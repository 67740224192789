exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-blog-2015-arrival-to-spain-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/2015-arrival-to-spain/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-blog-2015-arrival-to-spain-index-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-blog-2015-first-day-appignano-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/2015-first-day-appignano/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-blog-2015-first-day-appignano-index-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-blog-2015-prelude-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/2015-prelude/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-blog-2015-prelude-index-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-blog-2016-honduras-fast-intense-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/2016-honduras-fast-intense/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-blog-2016-honduras-fast-intense-index-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-blog-2022-react-guidelines-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/2022-react-guidelines/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-blog-2022-react-guidelines-index-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-blog-2023-adios-router-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/2023-adios-router/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-blog-2023-adios-router-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

